















































































































































import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue';
import DialogoDeBuscaDeVendedor from '@/components/metas/DialogoDeBuscaDeVendedor.vue';
import DialogoDeEdicaoDeMetas from '@/components/metas/DialogoDeEdicaoDeMetas.vue';
import AvatarDeUsuario from '@/components/usuario/AvatarDeUsuario.vue';
import { Meta, Usuario } from '@/models';
import { criarMeta } from '@/shareds/meta/meta-shareds';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore';
import { FindMetasUseCase } from '@/usecases';
import { SaveMetaUseCase } from '@/usecases/meta/SaveMetaUseCase';
import { nextTick } from '@/shareds/utils'
import { Ref, Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { DeleteMetaUseCase } from '@/usecases/meta/DeleteMetaUseCase';
import { MetaModule } from '@/store/vuex/meta/MetaStore';
import { obrigatorio } from '@/shareds/regras-de-form';
import Confirmacao from '@/components/ui/Confirmacao.vue';
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue';


@Component({
	components: {
		EdicaoGenerica,
		DialogoDeEdicaoDeMetas,
		DialogoDeBuscaDeVendedor,
		AvatarDeUsuario,
		Confirmacao,
		SeletorDeLojasDoUsuario,
	},
})

export default class EdicaoDeMetas extends Vue {
	@Ref() edicaoGenerica!: EdicaoGenerica<Meta>
	@Ref() dialogoDeEdicaoDeMetas!: DialogoDeEdicaoDeMetas
	@Ref() confirmacao!: Confirmacao
	@Prop({ type: String }) id!: string 
	@Prop({ type: Boolean }) novo!: boolean

	meta: Meta | null = null
	saveUseCase = new SaveMetaUseCase()
	findUseCase = new FindMetasUseCase()
	deleteUseCase = new DeleteMetaUseCase()
	versaoAnterior: Meta | null = null
	
	obrigatorio = obrigatorio
	erro: any | Error = null
	carregando = false
	respostaConfirmacao = false
	salvando = false
	$router: any;
	
	async salvar() {
		if(!this.meta) return
		if(!this.meta.ativarMeta && this.meta.vendedorAtribuido.length > 0 && !this.respostaConfirmacao ) {
			this.respostaConfirmacao = true
			this.confirmacao.mostrar()
			return
		}
		if(!this.meta.lojaId) {
			AlertModule.setInfo('Selecione a loja')
			return}
		try {
			this.salvando = true
			const meta: Meta = {
				...this.meta,
				vendedorAtribuido: [...this.meta.vendedorAtribuido ],
				ativarMeta : this.meta.ativarMeta,
				descricao : this.meta.descricao,
				comissao : this.meta.comissao,
				valor : this.meta.valor,	
			}
			const metaSalva = await this.saveUseCase.update(meta)
			this.id
				? MetaModule.atualizaMeta(metaSalva)
				: MetaModule.adicionaMeta(metaSalva)
			AlertModule.setSuccess('Meta salva com sucesso!')
			if (this.novo) {
				this.$router.push({
					name: 'Meta',
					params: { id: metaSalva.id },
				})
			}
			this.versaoAnterior = JSON.parse(JSON.stringify(this.meta))
			MetaModule.atualizarFiltro(true)
		} catch (error) {
			AlertModule.setError(error)
			this.respostaConfirmacao = false
		} finally {
			this.salvando = false
			this.respostaConfirmacao = false
			this.carregar()
		}
	}


	inserirVendedor(usuario: Usuario) {
		if (!this.meta) return
		if (!this.meta.ativarMeta) {
			AlertModule.setError('Meta precisa estar ativa para adicionar vendedor')
			return
		}
		const jaEstaNaMeta = this.meta.vendedorAtribuido.some(({ id }) => usuario.id === id) 
		if (jaEstaNaMeta) {
			AlertModule.setError(
				`${usuario.nome} já esta atribuido a meta`,	
			)
			return
		}
		const vendedor: Usuario = {
			...usuario,
			senha: !usuario.senha ? '' : usuario.senha,
			lojas: [],
			pdvs: [],
			cpf: usuario.cpf || null,
			email: usuario.email || null,
			vendedor: usuario.vendedor || false,
		}
		this.meta.vendedorAtribuido.push(vendedor)
	}

	async desvincularVendedor() {
		if(!this.meta) return
		this.meta.vendedorAtribuido = []
		this.salvar()
	}

	async carregar() {
		try {
			this.carregando = true
			const meta = this.novo
				? criarMeta()
				: await this.findUseCase.findById(this.id)
			this.meta = meta
			nextTick().then(() => {
				this.versaoAnterior = JSON.parse(JSON.stringify(this.meta))
			})
			MetaModule.setMetaSelecionada(this.meta)
		} catch (error) {
			this.erro = error
			AlertModule.setError(error)
		} finally {
			this.$nextTick(() => {
				this.carregando = false
			})
		}
	}

	displayPerfilTooltip(id: string) {
		const perfil = UsuariosModule.perfil(id)
		return perfil ? `${perfil.nome} - ` : ''
	}

	removerVendedorDaMeta(id: string) { 
		if (!this.meta) return
		this.meta.vendedorAtribuido = this.meta.vendedorAtribuido.filter(usuario => usuario.id !== id)
	}

	atualizarVersaoAnterior() {
		nextTick().then(() => {
			this.versaoAnterior = JSON.parse(JSON.stringify(this.meta))
		})
	}

	@Watch('id', {immediate: true})
	onChangeId() {
		this.carregar()
	}
}

